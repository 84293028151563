.images {
  display: flex;
}
.sale {
  max-width: 100%;
  overflow: scroll;
}
.images img {
  width: 400px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0, 187, 255);
  margin: 10px;
}
.images .image {
  margin: 10px;
}

@media (max-width: 1100px) {
  .images img {
    width: 300px;
  }
}
@media (max-width: 705px) {
  .images {
    width: 400px;
  }
}
@media (max-width: 440px) {
  .image {
    width: 300px;
  }
}

@media (max-width: 350px) {
  .images img {
    width: 150px;
    margin: 0;
  }
}
