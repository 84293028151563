.main {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.modalWindow {
  width: 80%;
  height: 400px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.maps {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}
.maps p {
  font-size: 16px;
  margin: 10px;
}

.google {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  border-radius: 10px;
  transition: background-color 1s;
}
.google:hover {
  background-color: rgb(26, 92, 113);
}
.google img {
  width: 120px;
  margin: 10px;
}
.yandex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  transition: background-color 1s;
  border-radius: 10px;
}
.yandex:hover {
  background-color: rgb(26, 92, 113);
}
.yandex img {
  width: 140px;
}
.textChoice {
  margin: 10px;
  font-size: 24px;
  text-align: center;
}
.google .ruMap {
  margin: 0 0 10px 0;
}
@media (max-width: 1000px) {
  .yandex {
    width: 240px;
  }
  .google {
    width: 240px;
  }
}
@media (max-width: 650px) {
  .yandex {
    width: 200px;
  }
  .google {
    width: 200px;
  }
}
@media (max-width: 550px) {
  .modalWindow {
    width: 95%;
    height: 300px;
  }
  .yandex {
    width: 200px;
  }
  .google {
    width: 200px;
  }
  .textChoice {
    font-size: 16px;
    margin-top: 0;
  }
  .maps {
    margin-bottom: 10px;
  }
}
@media (max-width: 450px) {
  .yandex {
    width: 140px;
  }
  .google {
    width: 140px;
  }
  .textChoice {
    font-size: 20px;
  }
}
