.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalWindow {
  width: 50%;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  color: red;
}

.topLine {
  width: calc(100%-10px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.header {
  font-size: 60px;
}
.description {
  font-size: 24px;
  margin: 20px 10px 10px 10px;
}
.delimiter {
  width: 95%;
  align-self: center;
  margin: 10px 0 10px 0;
  height: 2px;
  animation: delemiter 1s;
}

@keyframes delemiter {
  0% {
    width: 0;
  }
  100% {
    width: 95%;
  }
}

@media (max-width: 1010px) {
  .header {
    font-size: 40px;
  }
}
@media (max-width: 750px) {
  .modalWindow {
    width: 70%;
  }
}
@media (max-width: 750px) {
  .header {
    font-size: 40px;
  }
  .modalWindow {
    width: calc(100% - 20px);
    margin: 10px;
  }
}
@media (max-width: 360px) {
  .header {
    font-size: 24px;
  }
  .description {
    font-size: 20px;
  }
}
