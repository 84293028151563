.main {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.img {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-top: 20px;
}
.img p {
  margin: 0 10px 0 20px;
}
.img img {
  width: 400px;
  border-radius: 20px;
}

.text {
  font-size: 40px;
  color: rgb(115, 234, 18);
  margin: 20px 0 0 10px;
}

.exemple {
  font-size: 24px;
  margin: 10px;
}
@media (max-width: 1180px) {
  .img p {
    font-size: 22px;
  }
}
@media (max-width: 940px) {
  .img p {
    font-size: 18px;
  }
}
@media (max-width: 770px) {
  .img {
    flex-direction: column;
  }
  .text {
    text-align: center;
  }
  .img p {
    margin-top: 10px;
  }
  .exemple {
    font-size: 20px;
    margin: 10px 0 0 20px;
  }
}
@media (max-width: 410px) {
  .img img {
    width: calc(100vw - 20px);
    margin: 10px;
  }
  .text {
    font-size: 24px;
  }
  .img p {
    font-size: 16px;
    margin: 10px 0 0 0;
  }
  .exemple {
    font-size: 16px;
    margin: 10px 0 0 0;
  }
}
