.button {
  max-width: 36px;
  min-width: 36px;

  min-height: 36px;
  max-height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  transition: min-width 1s;
}

.burgerHr {
  max-width: 24px;
}
.mainButtonWindow {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonWindow {
  display: flex;
  justify-content: space-between;
  width: calc(100vw - 20px);
  background-color: rgb(36, 36, 36);
  border-radius: 10px;
  margin-top: 45px;
}
.buttonWindow_color {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 10px;
}

.link {
  font-size: 24px;
  margin: 20px 10px 20px 10px;
  animation: opacityLinks 0.4s;
}

.social {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
}
.social img {
  width: 50px;
  margin: 20px;
  animation: opacityButtonWindow 0.4s;
}

.colorMin {
  display: flex;
  width: calc(100vw - 20px);
  justify-content: space-between;
}
@keyframes opacityButtonWindow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes opacityLinks {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@media (min-width: 901px) {
  .button {
    opacity: 0;
    max-width: 0;
    min-width: 0;
    transform: translateY(-100px);
  }
}

@media (max-width: 400px) {
  .social img {
    width: 40px;
  }
  .links {
    min-height: 100%;
    justify-content: space-around;
  }
  .link {
    margin: 10px 1px 10px 1px;
  }
}
