.main {
  display: flex;
  flex-direction: column;
}

.helloContent {
  width: 100%;
}

@media (max-width: 1000px) {
}
@media (max-width: 855px) {
}
@media (max-width: 760px) {
}
@media (max-width: 670px) {
}

@media (max-width: 400px) {
}
