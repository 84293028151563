.danger {
  background-color: rgba(255, 132, 0, 0.8);
}

.ok {
  background-color: rgba(30, 255, 0, 0.8);
}

.main {
  margin: 10px;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: fixed;
}

.status {
  top: 45px;
  left: 0;
  width: 400px;
  height: 100px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 24;
  border-radius: 10px;
}
@media (max-width: 1200px) {
  .danger {
    font-size: 20px;
  }
  .ok {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .danger {
    font-size: 16px;
  }
  .ok {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .danger {
    font-size: 14px;
  }
  .ok {
    font-size: 14px;
  }
}
