.main {
  display: grid;
  grid-template-columns: 50% 50%;
}
.input {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.input input {
  width: 100%;
  background-color: silver;
  border: none;
  height: 40px;
  width: calc(100% - 80px);
  max-width: 500px;
  margin: 0 40px 0 40px;
}
.text {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.text p {
  font-size: 24px;
  max-width: 500px;
}
.searchedCatalog {
  grid-column: 1/3;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.color {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  transition: color 1s;
}
.color img {
  max-width: 500px;
  border-radius: 20px;
  border: 1px solid rgb(16, 173, 184);
  width: 99%;
}

.services {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.services img {
  max-width: 500px;
  border: 1px solid rgb(8, 131, 155);
  border-radius: 20px;
  width: 100%;
}

.serviceName {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 500px;
  height: 502px;
  border-radius: 20px;
  font-size: 0;
  transition: background-color 0.1s;
}

.serviceName:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: rgb(8, 131, 155);
  font-size: 10vw;
}
@media (max-width: 1050px) {
  .color img {
    width: 340px;
  }
  .services img {
    width: 340px;
  }
  .serviceName {
    width: 340px;
    height: 342px;
  }
  .text p {
    width: 80%;
  }
  .input input {
    width: 80%;
  }
}
@media (max-width: 850px) {
  .main {
    grid-template-columns: 1fr;
  }
  .color img {
    width: 500px;
    height: 500px;
  }
  .services img {
    width: 500px;
    height: 500px;
  }
  .serviceName {
    width: 500px;
    height: 502px;
  }
  .text {
    margin-top: 20px;
  }
  .text p {
    font-size: 20px;
  }
  .input {
    margin-top: 20px;
  }
}
@media (max-width: 510px) {
  .color img {
    width: 400px;
    height: 400px;
  }
  .services img {
    width: 400px;
    height: 400px;
  }
  .serviceName {
    width: 400px;
    height: 402px;
  }
}
@media (max-width: 420px) {
  .color img {
    width: 300px;
    height: 300px;
  }
  .services img {
    width: 300px;
    height: 300px;
  }
  .serviceName {
    width: 300px;
    height: 302px;
  }
  .text {
    margin-top: 16px;
  }
  .text p {
    font-size: 16px;
  }
  .input {
    margin-top: 16px;
  }
}
@media (max-width: 320px) {
  .color img {
    width: 240px;
    height: 240px;
  }
  .services img {
    width: 240px;
    height: 240px;
  }
  .serviceName {
    width: 240px;
    height: 242px;
  }
}
@media (max-width: 240px) {
  .color img {
    width: 140px;
    height: 140px;
  }
  .services img {
    width: 140px;
    height: 140px;
  }
  .serviceName {
    width: 140px;
    height: 142px;
  }
}
