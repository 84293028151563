.main {
  margin-top: 60px;
}
.main p {
  margin: 20px;
  font-size: 24px;
}
.underlineText {
  text-decoration: underline rgb(40, 170, 24);
}
.noPoint {
  text-decoration: underline rgb(0, 191, 255);
}
.link {
  color: rgb(0, 191, 255);
  transition: color 1s;
}
.link:hover {
  color: rgb(217, 243, 252);
}

@media (max-width: 600px) {
  .main p {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .main p {
    font-size: 16px;
  }
}
