.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
  max-width: 100%;
}
.mainService {
  width: 100%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: 0;
}

.name {
  font-size: 24px;
  margin: 0 10px 0 10px;
  max-width: 60%;
}

.name p {
  margin: 10px;
}

.topLine {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.topLine .searchInput {
  width: 25%;
  background-color: silver;
  border: none;
  height: 40px;
  margin: 10px 20px 10px 10px;
}

.mainPrice {
  display: flex;
}
.price {
  margin: 0 10px 0 0;
  font-size: 24px;
  display: flex;
  align-items: center;
  max-width: 30%;
}

.mainService .adminButton {
  margin: 0 10px 0 0;
  min-width: 60px;
  height: 34px;
  border-radius: 7px;
  transition: width, background-color, 0.4s;
  align-self: center;
  cursor: pointer;
  border: 1px solid white;
}
.addPrice {
  width: 40px;
  height: 40px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0);
  margin: 10px 10px 10px 0;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}
.adminButton:hover {
  background-color: red;
  color: white;
}
.addPrice:hover {
  background-color: rgba(52, 158, 110, 0.4);
  transition: width, background-color, 0.4s;
}

.socialMain {
  margin-top: 40px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1/3;
}
.socialMain {
  text-align: center;
  color: white;
}
.social {
  display: flex;
}
.social img {
  width: 40px;
  margin: 10px;
}
.topLineItem {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.topLineItem .price {
  margin: 10px;
}
@media (max-width: 1400px) {
  .name {
    font-size: 18px;
  }
  .mainService .price {
    font-size: 22px;
  }
}
@media (max-width: 1200px) {
  .items {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    min-width: 100%;
  }
  .mainService {
    width: calc(100% - 40px);
    margin: 0 20px 0 20px;
  }
}
@media (max-width: 750px) {
  .items {
    flex-direction: column;
    align-self: center;
    justify-content: center;
    max-width: 100%;
  }
  .name {
    font-size: 16px;
  }
  .mainService {
    width: calc(100% - 20px);
    margin: 0 10px 0 10px;
  }
  .mainService .price {
    font-size: 20px;
  }
  .topLine .searchInput {
    margin: 10px;
  }
}
@media (max-width: 650px) {
  .topLine .searchInput {
    width: 50%;
    margin: 45px 10px 10px 10px;
    width: calc(100% - 20px);
  }
}
@media (max-width: 360px) {
  .name {
    font-size: 16px;
  }
  .mainService .price {
    font-size: 16px;
  }
  .mainService button {
    height: 34px;
    font-size: 16px;
    margin: 10px;
  }
}
@media (max-width: 310px) {
  .button {
    flex-direction: column;
  }
  .button button {
    width: calc(100vw - 40px);
  }
}
