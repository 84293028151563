.main {
  display: flex;
  flex-direction: column;
}

.pushService {
  width: 100px;
  margin: 10px 10px 10px 20px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  color: rgb(0, 111, 111);
  height: 40px;
  cursor: pointer;
  transition: background-color 1s;
  align-self: flex-end;
  z-index: 40;
}
.pushService:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}
