.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transition: color 1s;
  width: 100%;
}
.wheel {
  display: flex;
  color: white;
  border: 1px solid white;
  flex-direction: column;
  margin: 16px;
  border-radius: 10px;
  max-width: calc(49% - 32px);
  max-height: 70vh;
  overflow: scroll;
}
.model {
  font-size: 50px;
  margin: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.name {
  margin: 0 10px 10px 17px;
  font-size: 16px;
}

.mainText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
}
.mainText img {
  width: 40%;
  margin: 10px;
  border-radius: 10px;
}
.text {
  font-size: 16px;
}
.linear {
  width: 95%;
  height: 1px;
  align-self: center;
  animation: hr 1s;
}

.wheel input {
  width: 10%;
  border-radius: 10px;
  align-self: center;
  margin: 10px;
  text-align: center;
  border: 1px solid white;
  color: white;
}
.inputDisc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputDisc i {
  font-size: 22px;
  margin-left: 10px;
}
.wheel button {
  border-radius: 10px;
  margin: 10px;
  transition: width, height, background-color, 0.4s;
}
.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addToSumButton {
  cursor: pointer;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0);
}
.addToSumButton:hover {
  background-color: rgba(52, 158, 110, 0.4);
}
.priceSize {
  color: rgb(40, 170, 24);
  overflow: scroll;
  font-size: 24px;
  margin: 10px;
  max-width: 100%;
}
.aleshaFond {
  font-size: 30px;
  margin: 10px 0 0 20px;
}
.savePostInfo {
  border: 1px solid white;
  border-radius: 10px;
  transition: background-color 0.4s;
  cursor: pointer;
}
.savePostInfo:hover {
  background-color: rgb(26, 92, 113);
  color: white;
}

.defaultValue {
  display: flex;
  align-items: center;
}
.priceAdmin {
  align-items: center;
  display: flex;
}
.adminButton {
  border: 1px solid white;
  width: calc(100% - 20px);
  height: 40px;
  cursor: pointer;
  color: rgb(0, 111, 111);
}
.adminButton:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}
@keyframes hr {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}
@media (min-width: 1750px) {
  .mainText img {
    width: 20%;
  }
}
@media (max-width: 1300px) {
  .wheel {
    max-width: calc(49% - 32px);
  }
}
@media (max-width: 900px) {
  .mainText img {
    width: 30%;
  }
  .wheel {
    max-width: calc((100% - 32px));
  }
  .sliderImg img {
    width: 40%;
  }
}
@media (max-width: 500px) {
  .defaultValue {
    font-size: 16px;
  }
  .name {
    font-size: 16px;
  }
  .inputDisc i {
    font-size: 16px;
  }
  .priceAdmin {
    font-size: 16px;
  }
  .inputName {
    font-size: 16px;
  }
  .inputText {
    font-size: 16px;
  }
  .adminButton {
    min-width: calc(100% - 20px);
    margin: 10px;
  }
  .aleshaFond {
    font-size: 20px;
  }
}
