.mainSelect {
  height: 30px;
  max-width: 100%;
}
select {
  height: 100%;
  min-width: 100%;
  border-radius: 7px;
  font-size: 16px;
}
