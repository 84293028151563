.items {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.item {
  margin-top: 10px;
  border: 1px solid rgb(129, 173, 189);
  border-radius: 20px;
  height: 60px;
  display: flex;
  align-items: center;
}
.linkItem {
  min-width: calc(100% - 20px);
  font-size: 20px;
  margin: 10px;
  display: flex;
  align-items: center;
}
.items :hover {
  background-color: rgb(112, 182, 208);
}

.arrows {
  display: flex;
  align-items: center;
  margin: 1px 0 0 20px;
}

@media (max-width: 700px) {
  .item {
    height: 100px;
  }
}
@media (max-width: 400px) {
  .item {
    height: 160px;
  }
}
