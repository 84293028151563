.saveButton {
  width: 100px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  color: rgb(0, 111, 111);
  height: 40px;
  cursor: pointer;
  transition: background-color 1s;
}
.saveButton:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}
.main input {
  width: 400px;
  background-color: silver;
  border: none;
  height: 40px;
  margin: 0 20px 10px 10px;
}
@media (max-width: 600px) {
  .main input {
    width: 240px;
    align-self: center;
  }
  .main {
    display: flex;
    flex-direction: column;
  }
  .saveButton {
    margin: 10px;
    width: 240px;
    align-self: center;
  }
}
