.newPost {
  display: grid;
  grid-template-columns: repeat(4 1fr);
}

.newPost .header {
  grid-column: 1/5;
  margin: 10px;
  font-size: 24px;
  max-width: calc(100vw - 20px);
  background-color: rgb(25, 57, 31);
  padding: 10px;
}

.newPost .description {
  margin: 10px;
  background-color: rgb(25, 57, 31);
  grid-column: 1/3;
  padding: 10px;
}
.newPost .img {
  background-color: rgb(25, 57, 31);
  margin: 10px;
  grid-column: 3/5;
  padding: 10px;
}

.newPost button {
  background-color: rgb(52, 150, 60);
  color: rgb(25, 57, 31);
  grid-column: 2/4;
  height: 40px;
  cursor: pointer;
}
.newPost button:hover {
  background-color: rgb(0, 255, 21);
}

.allPost {
  width: calc(100vw - 40px);
  margin: 20px;
  font-size: 24px;
}
.mainPost {
  display: flex;
  flex-direction: column;
}
.post {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text {
  display: flex;
  flex-direction: column;
  max-width: 60%;
}
.images {
  width: 30vw;
  overflow: scroll;
  display: flex;
}
.allPost img {
  width: 100%;
  margin: 10px;
  border-radius: 10px;
}
.deleteButton {
  width: 200px;
  height: 40px;
  cursor: pointer;
  align-self: flex-end;
  border: 1px solid white;
  border-radius: 10px;
  transition: background-color, 0.4s;
}
.deleteButton:hover {
  background-color: rgb(255, 0, 0);
}
.linear {
  width: calc(100vw - 20px);
  margin: 20px;
  align-self: center;
  animation: linear 1s;
}

@keyframes linear {
  0% {
    width: 0;
  }
  100% {
    width: calc(100vw - 20px);
  }
}
@media (max-width: 800px) {
  .images {
    width: 60vw;
  }
  .deleteButton {
    align-self: center;
    width: 60vw;
  }
  .newPost .img {
    grid-column: 1/5;
  }
  .newPost .description {
    grid-column: 1/5;
  }
  .text {
    font-size: 16px;
  }
}
@media (max-width: 565px) {
  .post {
    flex-direction: column-reverse;
  }
  .images {
    width: calc(100vw - 20px);
    margin: 10px 20px 10px 20px;
  }
  .deleteButton {
    width: calc(100vw - 20px);
    margin: 10px 20px 10px 20px;
  }
  .text {
    max-width: calc(100vw - 20px);
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .newPost button {
    grid-column: 1/5;
  }
}
