.infoLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.companyDescription {
  font-size: 24px;
  margin-left: 40px;
}
.line {
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 24px 0 20px;
}

@media (max-width: 1100px) {
  .line {
    width: 380px;
  }

  .companyDescription {
    font-size: 22px;
  }
}
@media (max-width: 880px) {
  .companyDescription {
    font-size: 20px;
  }
  .infoLine {
    flex-direction: column;
  }
  .line {
    width: 100%;
    margin: 20px;
  }
  .companyDescription {
    text-align: center;
    margin: 0 10px 0 10px;
    font-size: 16px;
  }
}
@media (max-width: 800px) {
  .companyDescription {
    font-size: 18px;
  }
}
@media (max-width: 390px) {
  .companyDescription {
    font-size: 16px;
    margin: 0;
  }
}
@media (max-width: 340px) {
  .line {
    width: 90%;
  }
  .companyDescription {
    font-size: 16px;
  }
}
