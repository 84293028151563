.search {
  margin: 100px 0 0 0;
}
.searchButton {
  width: 100px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  color: rgb(0, 111, 111);
  height: 40px;
  cursor: pointer;
  transition: background-color 1s;
}
.searchButton:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}
.user {
  display: flex;
  font-size: 20px;
  color: silver;
  width: 400px;
  justify-content: space-between;
  margin: 10px 10px 10px 40px;
  text-decoration: underline;
}
.inputPromoCode {
  width: 500px;
  background-color: silver;
  border: none;
  height: 40px;
  margin: 10px;
}
@media (max-width: 768px) {
  .inputPromoCode {
    width: 310px;
  }
  .user {
    margin: 10px 0 0 10px;
  }
}
@media (max-width: 492px) {
  .inputPromoCode {
    width: calc(100% - 20px);
  }
  .user {
    font-size: 16px;
    width: calc(100% - 20px);
  }
  .searchButton {
    width: calc(100% - 20px);
  }
}
@media (max-width: 370px) {
  .user p {
    font-size: 14px;
  }
}
