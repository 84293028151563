.main {
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.registration {
  border-radius: 20px;
  height: 400px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;
  text-align: center;
}

.inputs {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}
.inputs input {
  width: 100%;
  background-color: silver;
  border: none;
  height: 40px;
  margin: 10px;
}
/* rgb(71, 71, 71) */
.main button {
  width: 70%;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  color: rgb(0, 111, 111);
  height: 40px;
  cursor: pointer;
  transition: background-color 1s;
  background-color: white;
}
.main button:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}

.otherPage {
  margin-top: 10px;
  color: silver;
  font-size: 16px;
}

.responseMessage {
  width: 70%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.responseMessage p {
  margin: 10px;
}
.ok {
  background-color: rgb(53, 144, 172);
}
.error {
  background-color: rgb(23, 60, 71);
}
@media (max-width: 1000px) {
  .main button {
    width: 70%;
  }
  .registration {
    min-width: 70%;
  }
}
@media (max-width: 550px) {
  .main button {
    width: calc(100% - 40px);
  }
  .responseMessage {
    width: calc(100% - 40px);
    margin: 0 20px 0 20px;
  }
  .inputs {
    width: calc(100% - 40px);
  }
  .registration {
    min-width: calc((100% - 20px));
    text-align: center;
  }
}
