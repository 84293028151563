.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.phone {
  font-size: 24px;
  margin-top: 20px;
}
.social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.social img {
  width: 55px;
  margin: 10px;
  cursor: pointer;
}

@media (max-width: 400px) {
  .social img {
    width: 40px;
  }
}
