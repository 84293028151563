.formCreatedItem {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 11;
}

.inputAdmin {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: white;
  font-size: 22px;
  margin: 10px;
}

.inputAdmin input {
  width: 100%;
  background-color: silver;
  border: none;
  height: 40px;
}
.mainWindow {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.save {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  color: rgb(0, 111, 111);
  height: 40px;
  cursor: pointer;
  transition: background-color 1s;
}
.save:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}

@media (max-width: 500px) {
  .mainWindow {
    width: calc(100% - 20px);
    margin: 10px;
  }
  .inputAdmin {
    width: calc(100% - 20px);
    font-size: 14px;
    margin: 10px;
  }
  .save {
    width: calc(100% - 20px);
    margin: 10px;
  }
}
@media (max-width: 400px) {
  .save {
    width: calc(100% - 20px);
    margin: 10px;
  }
}
