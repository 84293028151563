.mainLinks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 90px;
}
.mainLinks .link {
  margin: 10px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid white;
}
.link div {
  border-radius: 10px;
}
.link:hover {
  background-color: rgb(27, 104, 128);
  text-decoration: none;
}
.link img {
  width: 30%;
  align-self: flex-end;
  margin: 10px;
  border-radius: 10px;
}
.admin {
  position: absolute;
  top: 45px;
  margin: 10px;
  left: calc(100% - 160px);
  height: 30px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: rgb(26, 92, 113);
  color: white;
  cursor: pointer;
}
.admin:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(26, 92, 113);
}
.admin p {
  margin: 2px;
}

.comeInLink {
  text-decoration: none;
  color: rgb(0, 111, 111);
}
.comeInToAcc button {
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  height: 40px;
  cursor: pointer;
  transition: background-color 1s;
  text-decoration: none;
}
.comeInToAcc button:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}

@media (max-width: 800px) {
  .mainLinks .link {
    font-size: 22px;
  }
  .mainLinks {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .mainLinks {
    grid-template-columns: 1fr 1fr;
  }
  .link img {
    width: 50%;
  }
}
@media (max-width: 460px) {
  .mainLinks {
    grid-template-columns: 1fr;
    margin-top: 120px;
  }
  .mainLinks .links {
    font-size: 16;
  }
  .link img {
    width: 30%;
  }
}
@media (max-width: 420px) {
  .admin {
    z-index: 30;
  }
}
