:root {
  --backgroundcolor: rgb(255, 0, 0);
}
.main {
  position: fixed;
  height: 45px;
  background-color: rgb(36, 36, 36);
  width: 100%;
  top: 0;
  z-index: 40;
  display: flex;
}
.navBar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.main .link {
  text-decoration: none;
  font-size: 28px;
  text-shadow: 7px 7px 4px rgb(0, 8, 255);
  margin-left: 10px;
  transition: color 1s;
}
.role {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 55px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  transition: background-color 1s;
}
.role:hover {
  background-color: rgba(255, 255, 255, 1);
}
.role p {
  margin: 2px;
}
.role .admin {
  text-decoration: none;
  color: rgb(26, 92, 113);
  border-radius: 10px;
  transition: box-shadow 1s;
}
.role .admin:hover {
  box-shadow: 0 0 20px 10px rgb(255, 255, 255);
}

.role .user {
  text-decoration: none;
}

.link:hover {
  font-size: 28px;
  color: rgb(4, 0, 82);
  text-shadow: 1px 1px 4px rgb(0, 8, 255);
}
.link:focus {
  font-size: 28px;
  color: rgb(4, 0, 82);
  text-shadow: 1px 1px 4px rgb(0, 8, 255);
}
.color {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  margin: 10px;
  font-size: 16px;
  min-width: 200px;
  border: 1px solid white;
  border-radius: 4px;
  z-index: 10;
}
.buttonBurger {
  margin: 3.5px 0 0 10px;
  position: absolute;
}
.colors {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0 10px 0 0;
}
.price {
  z-index: 11;
  margin: 0;
  display: flex;
  align-items: center;
}
.price .buttonPrice {
  min-width: 20px;
  height: 40px;
  margin: 0;
  cursor: pointer;
}
.price .buttonPrice:hover {
  background-color: greenyellow;
  color: rgb(0, 8, 255);
}

.login {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: rgb(26, 92, 113);
  border: 1px solid white;
  border-radius: 10px;
  margin: 2px;
  transition: background-color 0.4s;
}
.login:hover {
  background-color: rgba(26, 92, 113, 0.1);
}
.login p {
  margin: 10px;
  color: white;
}
@media (max-width: 1000px) {
  .role p {
    font-size: 20px;
    margin: 2px;
  }
}
@media (max-width: 900px) {
  .navBar .link {
    font-size: 0;
  }
  .colors {
    max-width: 0;
    z-index: -24;
    opacity: 0;
  }
  .role {
    font-size: 16px;
  }
}
@media (max-width: 420px) {
  .color {
    min-width: 100px;
    max-height: 45px;
    margin: 0 0 0 1px;
    font-size: 14px;
    align-self: center;
  }
}
