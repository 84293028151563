.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.helloContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(rgb(129, 173, 189), rgb(26, 92, 113));
  width: 100%;
}
.mainLine {
  min-width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}
.img {
  width: 24%;
  margin: 3% 0 10px 0;
}
.helloContent .img img {
  align-self: flex-end;
  width: 100%;
  border-radius: 50px;
  transition: all 1s;
  animation: img 1s;
}
.helloContent .img {
  display: flex;
  justify-content: center;
}
.helloContent .img img:hover {
  border-radius: 400px;
  transform: rotate(-45deg);
}
hr {
  width: 100%;
  animation: hr 1s;
  border: none;
  height: 1px;
  background-color: white;
}
.info {
  font-size: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.info a {
  color: rgb(255, 255, 255);
  margin-top: 14px;
  margin-bottom: 14px;
  z-index: 10;
}
.info p {
  color: rgb(255, 255, 255);
  margin-top: 14px;
  margin-bottom: 14px;
  z-index: 10;
}
.phoneNumber {
  animation: mainText 1s;
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0 0 0 1px;
}
.whatsAppNumber {
  width: 30px;
  margin: 0 0 0 10px;
}
.VK {
  animation: mainText 2s;
  display: flex;
  align-items: center;
}
.VK p {
  margin: 0 0 0 10px;
}
.dye {
  animation: mainText 3s;
  display: flex;
  align-items: center;
}
.dye p {
  margin: 0 10px 0 0;
}
.linkToCatalog {
  animation: mainText 4s;
}
.limiter {
  margin-top: 40px;
  width: 95%;
  animation: hrLimiter 1s;
}
@keyframes hr {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes hrLimiter {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}
@keyframes hrMin {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@keyframes mainText {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100;
  }
}
@keyframes img {
  0% {
    border-radius: 400px;
  }
  100% {
    border-radius: 50px;
  }
}

@media (max-width: 1200px) {
}
@media (max-width: 1200px) {
  .info {
    font-size: 24px;
    margin: 0 0 10px 0;
  }
  .phoneNumber {
    height: 14px;
  }
}
@media (max-width: 900px) {
  .info {
    font-size: 22px;
    margin: 0 0 10px 0;
  }
}
@media (max-width: 800px) {
  .helloContent {
    flex-direction: column-reverse;
  }
  .info {
    font-size: 24px;
    width: 70%;
  }
  .helloContent .img {
    min-width: 50%;
  }
}
@media (max-width: 650px) {
  .info {
    font-size: 24px;
    width: 80%;
  }
}
@media (max-width: 500px) {
  .mainLine {
    min-width: 100%;
    top: 20px;
  }
  .helloContent .img {
    width: 30%;
  }
  .info {
    font-size: 22px;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }
  .info p {
    max-width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  hr {
    width: 90%;
    animation: hrMin 1s;
  }
  .limiter {
    opacity: 0;
    margin: 0;
  }
}
@media (max-width: 520px) {
  .info {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .info {
    font-size: 14px;
  }
}
