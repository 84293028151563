.main {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.colorMain {
  height: 40px;
  animation: color 2s;
  margin-top: 4px;
  margin-bottom: 4px;
}
.color {
  height: 100%;
  width: 20px;
  margin-left: 10px;
  border-radius: 10px;
}

@keyframes color {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100;
  }
}
@media (max-width: 800px) {
  .colorMain {
    height: 20px;
  }
  .color {
    width: 40px;
    height: 10px;
  }
}
@media (max-width: 400px) {
  .color {
    width: 30px;
    margin-left: 1px;
  }
}
