.colorRal {
  border: 1px solid rgb(0, 26, 255);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 7px;
  text-decoration: none;
}
.colorRal:hover {
  border: 1px solid rgb(0, 191, 255);
}
.color {
  width: 120px;
  height: 60px;
  border-radius: 10px;
}
.text {
  display: flex;
  flex-direction: column;
}
.notHaveColor {
  width: 100vw;
  text-align: center;
}
.notHaveColor p {
  color: white;
  font-size: 24px;
}
.text p {
  margin: 0 20px 0 20px;
}

@media (max-width: 600px) {
  .text p {
    margin: 0 1px 0 1px;
  }
}
@media (max-width: 520px) {
  .text {
    flex-direction: row;
    align-items: center;
  }
  .text p {
    font-size: 14px;
    margin: 10px;
  }
}
@media (max-width: 450px) {
  .text {
    flex-direction: column;
    justify-content: center;
  }
  .text p {
    font-size: 14px;
    margin: 1px 10px 1px 10px;
  }
}
