.main {
  display: flex;
  flex-direction: column;
  font-size: 40px;
  align-items: flex-end;
  overflow: scroll;
}
.main i {
  font-size: 30px;
  margin: 40px 20px 0 40px;
}
.main p {
  margin: 0 20px 0 20px;
}
.price {
  display: flex;
  align-items: center;
}
.buttonPrice {
  min-width: 40px;
  height: 40px;
  margin-left: 0;
  cursor: pointer;
  font-size: 24px;
  background-color: rgb(26, 92, 113);
  border: 1px solid white;
  border-radius: 10px;
}
.buttonPrice:hover {
  color: rgb(106, 106, 255);
}
.setIsOpen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
