.mainServiceChoice {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  border-radius: 20px;
}
.main:hover {
  animation: gradient 4s;
}

.images {
  width: 100%;
  display: flex;
  justify-content: center;
}
.images img {
  width: 60%;
}
.main .text {
  font-size: 24px;
  margin-bottom: 10px;
}
.text {
  margin: 10px;
}
.text h3 {
  text-align: center;
  margin: 10px 0;
}
.socialMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
.socialMain p {
  font-size: 16px;
}
.socialMain img {
  width: 40px;
}
.social {
  margin-top: 10px;
}
@keyframes gradient {
  50% {
    box-shadow: 0 -1000px 100px -100px rgb(129, 173, 189) inset;
  }
}
@media (max-width: 1300px) {
  .main .text {
    font-size: 20px;
  }
}

@media (max-width: 1050px) {
  .mainServiceChoice {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 650px) {
  .mainServiceChoice {
    grid-template-columns: 1fr;
  }
  .main .text {
    font-size: 20px;
  }
}
@media (max-width: 510px) {
  .main .text {
    font-size: 20px;
  }
}
@media (max-width: 400px) {
  .main .text {
    font-size: 16px;
  }
}
