.modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.adminBar {
  border-radius: 10px;
  border: 1px outset white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 500px;
}
.adminBar i {
  color: white;
  font-size: 40px;
  margin: 10px;
}
.inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 10px;
}
.inputs i {
  font-size: 16px;
  margin: 10px 0;
}
.input {
  display: flex;
  flex-direction: column;
}
.inputs input {
  width: 100%;
  background-color: silver;
  border: none;
  height: 40px;
}

.catalogPrice {
  align-self: flex-end;
  margin: 10px;
  color: white;
}
.adminBar button {
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  color: rgb(0, 111, 111);
  height: 40px;
  cursor: pointer;
  transition: background-color 1s;
}
.adminBar button:hover {
  background-color: rgb(26, 45, 45);
  color: white;
}

@media (max-width: 500px) {
  .adminBar {
    width: calc(100% - 20px);
    margin: 10px;
  }
}
